// react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { GoogleLoginButton } from "react-social-login-buttons";

// form handling
import { Formik, Form, FormikState } from 'formik';
import * as Yup from 'yup';

// custom components
import ActivityIndicator from "../ActivityIndicator";
import Alert from "../Alert";
import Button from "../Button";
import InputText from '../InputText';
import Modal from "../Modal";
import Type from "../Type";

// other utilities
import { openerURL } from '../../constants/urls';
import statusCode from '../../constants/statusCode';
import { supportEmail } from '../../constants/urls';
import {
  CreateUserError,
  CreateUserErrorActionCode,
  createUser,
  createMeshUser,
  NewUserRecord,
  SelfSignupNewUserRecord,
} from '../../api/createUser';
import { logInWithGoogle } from "../../api/auth";

interface SignUpFormProps {
  refCode?: string;
}

interface SignupFormValues {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ refCode }) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [creationError, setCreationError] = useState<unknown>(null);

  // error conditions that we show to the user
  let weakPassword = false;
  let accountAlreadyExists = false;

  // log errors in Sentry
  if (__PRODUCTION__) {
    useEffect(() => {
      if (creationError && !(creationError instanceof CreateUserError)) {
        Sentry.captureException(creationError);
      }
    }, [creationError]);
  }

  // something went wrong when creating the account
  if (creationError) {
    if (creationError instanceof CreateUserError) {
      switch(creationError.code) {
      case CreateUserErrorActionCode.WeakPassword:
        weakPassword = true;
        break;
      case CreateUserErrorActionCode.AccountAlreadyExists:
        accountAlreadyExists = true;
        break;
      }
    } else {
      // unexpected error
      console.error('failed to create user:', creationError);
      return (
        <Alert
          title="Something went wrong!"
          status={statusCode.danger}
          message={`Try your request again, or contact ${supportEmail}`}
          closeable={false}
        />
      );
    }
  }

  return (
    <>
      <Modal open={isProcessing}>
        <Type size="subtitle">
          Creating account...
          <ActivityIndicator className="inline" size="large" />
        </Type>
      </Modal>
      <Formik<SignupFormValues>
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          firstname: Yup.string()
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
          lastname: Yup.string()
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
          email: Yup.string()
            .email('Invalid email address.')
            .required('Required.'),
          password: Yup.string()
            .min(6, 'Must be at least 6 characters long.')
            .required('Required'),
        })}
        onSubmit={async (userData: SignupFormValues) => {
          setIsProcessing(true);
          try {
            const newUser: NewUserRecord = {
              firstname: userData.firstname,
              lastname: userData.lastname,
              email: userData.email,
            };
            if (refCode) {
              newUser.referralCode = refCode;
            }
            // create the newaccount
            const _result = await createUser(newUser, userData.password);
            //console.log(`new user is `, _result);
            void navigate("/download/newUser");
          } catch (e) {
            console.error('failed to create user: ', e);
            setCreationError(e);
            setIsProcessing(false);
          }
        }}
      >
        {({ isSubmitting, errors, touched }: FormikState<SignupFormValues>) => {
          if (weakPassword) {
            errors.password = "This password is too weak.";
            touched.password = true;
          }
          if (accountAlreadyExists) {
            errors.email = "An account with this email already exists.";
            touched.email = true;
          }
          return (
            <Form className="flex flex-col gap-y-2">
              <Type className='text-center pb-4'>
                Already have an account?
                <a
                  className="ml-2 text-blue-700 hover:underline visited:text-blue-700"
                  href={openerURL}
                  target='_blank' rel='noreferrer'
                >
                  Launch MeshOS
                </a>
              </Type>
              <InputText
                label='First Name'
                name='firstname'
                placeholder='Jack'
                size='small'
                note={errors.firstname && touched.firstname ? errors.firstname : undefined}
                status={errors.firstname && touched.firstname ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <InputText
                label='Last Name'
                name='lastname'
                placeholder='Sparrow'
                size='small'
                note={errors.lastname && touched.lastname ? errors.lastname : undefined}
                status={errors.lastname && touched.lastname ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <InputText
                label='Email'
                name='email'
                type='email'
                placeholder='rumlover@pirate.net'
                size='small'
                note={errors.email && touched.email ? errors.email : undefined}
                status={errors.email && touched.email ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <InputText
                label='Password'
                name='password'
                type='password'
                placeholder=''
                size='small'
                note={errors.password && touched.password ? errors.password : undefined}
                status={errors.password && touched.password ? statusCode.danger : statusCode.none}
                noComplete
                field
              />
              <div className="flex justify-center">
                <Button type="submit" disabled={isSubmitting}>
                  Sign Up
                </Button>
              </div>
              <div className="flex justify-between items-center pb-4 pt-4">
                <hr className="horizontalBar"/>
                <p className="signUpOrDivider">or</p>
                <hr className="horizontalBar"/>
              </div>
              <div className="flex flex-col items-center">
                <GoogleLoginButton
                  className="max-w-72"
                  onClick={() => {
                    setIsProcessing(true);
                    logInWithGoogle()
                      .then(([user, isNew, profile]) => {
                        if (!isNew) {
                          console.log('existing user');
                          void navigate("/download");
                          return;
                        }

                        //console.log('user', user);
                        //console.log('profile', profile);

                        // extract the name parts
                        const names = profile?.name.split(/\s+/) ?? [];
                        const lastname = names.pop() ?? "";
                        const firstname = names.join(" ") ?? "";
                        const newUser: SelfSignupNewUserRecord = {
                          firstname,
                          lastname,
                          email: user.email!,
                          firebaseUid: user.uid,
                        };
                        if (refCode) {
                          newUser.referralCode = refCode;
                        }
                        // create the new account
                        createMeshUser(newUser)
                          .then((resp) => {
                            console.log(`Mesh user created: ${resp.meshId}`);
                            // no need to send verification email
                            void navigate("/download/newUser");
                          })
                          .catch((err) => {
                            console.error('failed to create user: ', err);
                            setCreationError(err);
                            setIsProcessing(false);
                          });
                      })
                      .catch((err) => {
                        console.error('failed to login via Google: ', err);
                        setCreationError(err);
                        setIsProcessing(false);
                      });
                  }}
                >
                  <span>Continue with Google</span>
                </GoogleLoginButton>
              </div>
              <Type className='text-center pt-4'>
                By creating an account, you agree to the
                <a
                  className="ml-2 text-blue-700 hover:underline visited:text-blue-700"
                  href="/terms"
                  target="_blank" rel="noreferrer"
                >
                  Terms of Service
                </a>
              </Type>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default SignUpForm;
