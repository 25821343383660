
// MeshOS home page
export const securityPrefix = __EMULATOR__ ? 'http://' : 'https://';
export const baseURL = __EMULATOR__ ? 'localhost:3000' : (__PRODUCTION__ ? 'meshos.com' : 'meshos-dev.web.app');
export const fullURL = securityPrefix + baseURL;

// open.meshos.com subdomain
export const openerSubdomain = 'open';
export const openerURL = securityPrefix + openerSubdomain + '.' + baseURL;

// Discord links
export const discordInvite = 'https://discord.gg/mnfhsVV9QA';
export const discordDeepLink = 'https://discord.com/channels/1180024499505217547/1180024499505217550';

// application release feed
export const appReleaseFeed = 'https://s3-us-west-1.amazonaws.com/us-west-1-meshos-downloads/releases/osx/beta/channel.xml';

export const supportEmail = "support@meshos.com";