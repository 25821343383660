import React from 'react';

import propOrDefault from '../../utils/propOrDefault';

import { UserShallow } from '../../constants/database-shallow';

import { Link } from 'react-router-dom';

const sizeLookup: Record<string, string> = {
  large: 'h-20 w-20',
  small: 'h-8 w-8',
  hero: 'h-32 w-32 shadow-sm mr-6 mt-3',
};

const textSizeLookup: Record<string, string> = {
  large: 'text-3xl',
  small: 'text-xs',
  hero: 'text-5xl',
};

const textSizeLookupTriplet: Record<string, string> = {
  large: 'text-xl',
  small: 'text-2xs',
  hero: 'text-3xl',
};

export interface UserLinkProps {
  size?: 'large' | 'small' | 'hero';
  user: UserShallow;
  forceInitials?: boolean;
  unlinked?: boolean;
  publisher?: boolean;
  id?: string;
}

export function UserLink(props: UserLinkProps) {
  const size = propOrDefault(props.size, 'small');
  const unlinked = size === 'hero' ? true : props.unlinked;

  const innerClass =
    'flex-shrink-0 rounded-full ' +
    sizeLookup[size] +
    (!props.user.publisherProfile
      ? size === 'small'
        ? ' shadow-sm m-1'
        : ' shadow-sm m-2'
      : '');

  let img;

  if (
    (!props.user.image || props.forceInitials) &&
    props.user.initials &&
    props.user.color
  ) {
    img = (
      <div
        className={innerClass + ' text-white justify-center items-center flex'}
        style={{ backgroundColor: props.user.color }}
        id={props.id ?? undefined}
      >
        <span
          className={
            'font-sans font-bold ' +
            (props.user.initials.length === 3
              ? textSizeLookupTriplet[size]
              : textSizeLookup[size])
          }
          style={{ pointerEvents: 'none' }}
        >
          {props.user.initials}
        </span>
      </div>
    );
  } else {
    img = (
      <img
        alt={props.user.displayName + ' profile image'}
        className={innerClass}
        src={
          props.publisher &&
          props.user.publisherProfile?.image
            ? props.user.publisherProfile.image
            : props.user.image
        }
        id={props.id ?? undefined}
      />
    );
  }

  if (
    unlinked ||
    !props.user.publisherProfile?.slug
  ) {
    return img;
  } else {
    return (
      <Link
        className={
          'flex grow-lg shadow-sm hover:shadow-lg rounded-full p-0 ' +
          (size === 'small' ? 'h-8 w-8 m-1' : 'h-20 w-20 m-2')
        }
        to={'/market/publisher/' + props.user.publisherProfile.slug}
      >
        {img}
      </Link>
    );
  }
}

export default UserLink;
