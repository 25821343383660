import React, { useState } from 'react';

import { Jimp } from "jimp";
import Cropper from 'react-cropper';
import Dropzone from 'react-dropzone';
import Avatar from 'react-avatar-edit';
import { Field, FieldProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import Button from '../Button';
import Type from '../Type';
import Modal from '../Modal';

import fallback from '../../assets/images/profileFallback.jpg';

export interface InputUploaderProps {
  initialSrc?: string;
  name?: string;
  profile?: boolean;
  aspectRatio?: number;
}

export function InputUploader(props: InputUploaderProps) {
  const [file, setFile] = useState<string|null>(null);
  const [open, setOpen] = useState(false);
  const [override, setOverride] = useState(false);

  const crop = async (info: Cropper.CropEvent<HTMLImageElement>, update?: (name: string, base64: string) => void) => {
    if (file) {
      const image = await Jimp.read(file);
      if (info.detail) {
        const x = info.detail?.x ?? 0;
        const y = info.detail?.y ?? 0;
        const w = info.detail?.width ?? 50;
        const h = info.detail?.height ?? 50;
        image.crop({ x, y, w, h});
      }
      const preview = await image.getBase64('image/jpeg');
      if (update && props.name) {
        update(props.name, preview);
      }
    }
  };

  const content = (field: FieldProps<string, unknown>) => (
    <div>
      <Modal open={open} toggleState={() => setOpen(!open)}>
        <div>
          <Type size="subheading">Add Photo</Type>
          {props.profile ? (
            <Avatar
              {...{
                width: 444,
                height: 295,
                onCrop: (base64) => {
                  if (field && props.name) {
                    void field.form.setFieldValue(props.name, base64);
                  }
                },
                label: 'Choose an image',
              }}
            />
          ) : file ? (
            <Cropper
              {...{
                src: file,
                style: { height: 400, width: '100%' },
                aspectRatio: props.aspectRatio ?? 1.777777778,
                guides: false,
                viewMode: 2,
                rotatable: false,
                autoCropArea: 1,
                crop: (event) => {
                  void crop(event, (name, base64) => {
                    void field.form.setFieldValue(name, base64);
                  });
                },
              }}
            />
          ) : (
            <Dropzone
              onDrop={(acceptedFiles) => {
                const reader = new FileReader();
                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = () => {
                  const result = reader.result;
                  if (result instanceof ArrayBuffer) {
                    const str = new TextDecoder('utf-8').decode(result);
                    setFile(str);
                  } else {
                    setFile(result);
                  }
                };
                reader.readAsDataURL(acceptedFiles[0]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="pt-32 pb-32 bg-gray-100 flex justify-center items-center">
                      <div className="rounded-lg shadow-sm border border-blue-500 border-solid p-4 pl-8 pr-8 text-center text-blue-500 bg-white">
                        <FontAwesomeIcon
                          icon={faCloudUploadAlt}
                          className="text-4xl mb-2"
                        />
                        <Type size="subheading">Upload Photo</Type>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          <br />
          <div className="flex justify-between">
            <Button
              onClick={() => {
                if (file) {
                  setFile(null);
                } else {
                  setOpen(false);
                }
              }}
              framing="outline"
              marginless
              type="button"
            >
              <span>Cancel</span>
            </Button>
            <Button
              onClick={() => {
                if (!props.name) { return; }
                setFile(null);
                setOpen(false);
                void field.form.setFieldValue(props.name, null);
                setOverride(true);
              }}
              marginless
              type="button"
              framing="unframed"
            >
              Remove Photo
            </Button>
            <Button
              onClick={() => {
                setOverride(false);
                setOpen(false);
              }}
              marginless
              type="button"
            >
              Upload
            </Button>
          </div>
        </div>
      </Modal>
      <button
        className={
          props.profile
            ? 'flex justify-center items-center border-0 grow rounded-full p-0 outline-hidden border-gray-100 border-solid focus-within:border-blue-300'
            : 'p-0 border-0 outline-hidden border-gray-100 border-solid focus-within:border-blue-300 w-full'
        }
        onClick={() => setOpen(true)}
        type="button"
      >
        <img
          className={
            'cursor-pointer ' +
            (props.profile
              ? 'rounded-full h-32 w-32 shadow-sm bg-black'
              : 'w-full')
          }
          src={
            override
              ? props.aspectRatio
                ? 'https://via.placeholder.com/500x' +
                Math.round(500 / (props.aspectRatio ?? 1.777777778)) +
                '?text=Click%20to%20upload%20a%20photo.'
                : fallback
              : field.field.value
                ? field.field.value
                : props.initialSrc ?? props.aspectRatio
                  ? 'https://via.placeholder.com/500x' +
                  Math.round(500 / (props.aspectRatio ?? 1.777777778)) +
                  '?text=Click%20to%20upload%20a%20photo.'
                  : fallback
          }
          alt="Upload Profile"
        />
      </button>
    </div>
  );

  return (
    <Field name={props.name}>
      {(field: FieldProps<string, unknown>) => <div>{content(field)}</div>}
    </Field>
  );
}

export default InputUploader;
