import React, { useState, useEffect } from 'react';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faLock,
  faLongArrowAltRight,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import { GoogleLoginButton } from "react-social-login-buttons";
import { createMeshUser, SelfSignupNewUserRecord } from '../../api/createUser';

import statusCode from '../../constants/statusCode';

import {
  logIn,
  logOut,
  resetPassword,
  useFirebaseAuth,
  logInWithGoogle,
} from '../../api/auth';

import ActivityIndicator from '../../components/ActivityIndicator';
import Type from '../../components/Type';
import InputText from '../../components/InputText';
import Button from '../../components/Button';

const errorMessages: Record<string, string> = {};
errorMessages['The password is invalid or the user does not have a password.'] =
  'Uh oh! Incorrect email/password combination...';
errorMessages[
  'There is no user record corresponding to this identifier. The user may have been deleted.'
] =
  errorMessages[
    'The password is invalid or the user does not have a password.'
  ];

interface loginVals {
  email: string;
  password: string;
}

interface resetVals {
  email: string;
}

const ResetSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const Login: React.FC<unknown> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const redirect = params.redirect ?? 'account';
  const [user, reloading] = useFirebaseAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const [resetting, setResetting] = useState(false);
  const [sentReset, setSentReset] = useState(false);

  const success = () => {
    setLoading(false);
    setError('');
  };

  const err = (err: Error) => {
    setLoading(false);
    setError(err.message);
    // console.error(err);
  };

  const login = (values: loginVals) => {
    setLoading(true);
    logIn(values.email, values.password)
      .then((loggedInUser) => {
        // redirect if they still need to verify their email
        if (!loggedInUser.emailVerified) {
          void navigate('/verifyemail');
          return;
        }
        void navigate('/' + decodeURIComponent(redirect));
      })
      .catch(err);
  };

  const loginWithGoogleClicked = () => {
    setLoading(true);
    logInWithGoogle()
      .then(([user, isNew, profile]) => {
        if (!isNew) {
          void navigate("/download");
          void navigate('/' + decodeURIComponent(redirect));
          return;
        }

        //console.log('user', user);
        //console.log('profile', profile);

        // extract the name parts
        const names = profile?.name.split(/\s+/) ?? [];
        const lastname = names.pop() ?? "";
        const firstname = names.join(" ") ?? "";
        const newUser: SelfSignupNewUserRecord = {
          firstname,
          lastname,
          email: user.email!,
          firebaseUid: user.uid,
        };
        // create the new account
        createMeshUser(newUser)
          .then((resp) => {
            console.log(`Mesh user created: ${resp.meshId}`);
            // no need to send verification email
            void navigate("/download/newUser");
          })
          .catch((err) => {
            console.error('failed to create user: ', err);
            setLoading(false);
            setError(err);
          });
      })
      .catch((err) => {
        console.error('failed to login via Google: ', err);
        setLoading(false);
        setError(err);
      });
  };

  const logout = () => {
    setLoading(true);
    logOut().then(success).catch(err);
  };

  const reset = (values: resetVals) => {
    setLoading(true);
    setSentReset(false);
    resetPassword(values.email)
      .then(() => {
        success();
        setSentReset(true);
      })
      .catch(err);
  };

  // log errors in Sentry
  if (__PRODUCTION__) {
    useEffect(() => {
      if (error) {
        Sentry.captureException(error);
      }
    }, [error]);
  }

  return (
    <div>
      <Helmet>
        <title>MeshOS Login</title>
      </Helmet>
      <div className="relative w-full mb-6">
        <div className="max-w-md mx-auto lg:min-h-[8rem] p-6 bg-gray-200">
          {loading || reloading ? (
            <ActivityIndicator />
          ) : user ? (
            <div className="fade-in">
              <Type size="subheading">Logged in as</Type>
              <Type size="heading">
                <span>{user.email}</span>
              </Type>
              <br />
              <hr />
              <br />
              <Link to={'/' + redirect}>
                <Button full>
                  <span>
                    Continue <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </span>
                </Button>
              </Link>
              <Button onClick={logout} color="secondary" full>
                <span>
                  Sign Out <FontAwesomeIcon icon={faSignOutAlt} />
                </span>
              </Button>
            </div>
          ) : resetting ? (
            <Formik<resetVals>
              initialValues={{
                email: '',
              }}
              validationSchema={ResetSchema}
              onSubmit={(values) => {
                reset(values);
              }}
            >
              {(formProps) => (
                <Form>
                  <Type size="heading">Reset Password</Type>
                  <hr />
                  {error ? (
                    <Type className="text-red-500">
                      <span>
                        {errorMessages[JSON.stringify(error)] || 'Uh oh! Something went wrong...'}
                      </span>
                    </Type>
                  ) : null}
                  {sentReset ? (
                    <Type className="text-green-700">
                      <span>
                        Reset email sent. Please check your email and follow
                        the intructions to reset your password.
                      </span>
                    </Type>
                  ) : null}
                  <br />
                  <InputText
                    field
                    name="email"
                    placeholder="Email"
                    label="Email"
                    type="email"
                    icon={faEnvelope}
                    note={formProps.errors.email}
                    status={
                      formProps.errors.email ? statusCode.danger : statusCode.none
                    }
                  />
                  <div>
                    <Button type="submit" full>
                      <span>
                        Send Reset Email{' '}
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </span>
                    </Button>
                    <Button
                      onClick={() => {
                        setSentReset(false);
                        setError(undefined);
                        setResetting(false);
                      }}
                      type="button"
                      framing="outline"
                      full
                      size="small"
                    >
                      <span>Back to Login</span>
                    </Button>
                  </div>
                  <br />
                </Form>
              )}
            </Formik>
          ) : (
            <Formik<loginVals>
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={(values: loginVals) => {
                login(values);
              }}
            >
              <Form>
                <Type size="heading">Login to MeshOS</Type>
                {error ? (
                  <Type className="text-red-500">
                    <span id={'error'}>
                      {errorMessages[JSON.stringify(error)] || 'Uh oh! Something went wrong...'}
                    </span>
                  </Type>
                ) : null}
                <br />
                <InputText
                  field
                  name="email"
                  placeholder="Email"
                  label="Email"
                  type="email"
                  icon={faEnvelope}
                />
                <InputText
                  field
                  name="password"
                  placeholder="Password"
                  label="Password"
                  type="password"
                  icon={faLock}
                />
                <div className='flex flex-col items-center m-auto max-w-72'>
                  <Button type="submit" full>
                    <span>
                      Login <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </span>
                  </Button>
                  <Button
                    onClick={() => {
                      setSentReset(false);
                      setError(undefined);
                      setResetting(true);
                    }}
                    type="button"
                    framing="outline"
                    size="small"
                    full
                  >
                    <span>Forgot Password</span>
                  </Button>
                </div>
                <div className="flex justify-between items-center pb-4 pt-4">
                  <hr className="horizontalBar"/>
                  <p className="signUpOrDivider">or</p>
                  <hr className="horizontalBar"/>
                </div>
                <div className="flex flex-col items-center">
                  <GoogleLoginButton
                    className="max-w-72"
                    onClick={loginWithGoogleClicked}
                  >
                  </GoogleLoginButton>
                </div>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
