import React, { JSX } from 'react';

import propOrDefault from '../../utils/propOrDefault';

const sizeLookup: Record<string, string> = {
  '100': 'w-full',
  '66': 'w-full md:w-2/3',
  '50': 'w-full lg:w-1/2',
  '33': 'w-full md:w-1/3',
  '25': 'w-full sm:w-1/2 lg:w-1/4',
};

export interface ContainerProps {
  layout:
    | '100'
    | '50-50'
    | '33-66'
    | '66-33'
    | '33-33-33'
    | '25-25-50'
    | '25-50-25'
    | '50-25-25'
    | '25-25-25-25';
  content: JSX.Element[];
  padding?: 'p-2' | 'p-4' | 'p-6';
  unframed?: boolean;
  unshadowed?: boolean;
  overflow?: boolean;
  marginless?: boolean;
  undivided?: boolean;
}

export function Container(props: ContainerProps) {
  const chunks = props.layout.split('-');
  const blocks = new Array<JSX.Element>();
  const content = [...props.content];
  const unframed = propOrDefault(props.unframed, false);
  const unshadowed = propOrDefault(props.unshadowed, false);
  const overflow = propOrDefault(props.overflow, false);
  const marginless = propOrDefault(props.marginless, false);
  const undivided = propOrDefault(props.undivided, false);

  const maxLen = chunks.length - 1;

  for (let i = 0; i < chunks.length; i++) {
    blocks.push(
      <div
        key={chunks[i]}
        className={
          'border-box flex flex-stretch ' +
          sizeLookup[chunks[i]] +
          (props.padding ? ' border-box ' + props.padding : '') +
          (unframed ? '' : ' border-gray-100 border-solid border-0') +
          (undivided
            ? ''
            : i === 0
              ? ' border-r'
              : i === maxLen
                ? ' border-l'
                : ' border-l border-r')
        }
      >
        {content.shift()}
      </div>
    );
  }

  return (
    <div
      className={
        (unframed ? '' : 'bg-white ') +
        (unshadowed ? '' : 'shadow-sm ') +
        (marginless ? '' : 'mb-4 ') +
        (overflow ? '' : 'overflow-hidden ') +
        'border-box flex rounded flex-wrap items-stretch w-full'
      }
    >
      {blocks ? blocks.map((block) => block) : null}
    </div>
  );
}

export default Container;
