import React from 'react';
import { Helmet } from 'react-helmet-async';

import Type from '../../components/Type';
import SignupButton from '../../components/SignupButton';

//import emailIMG from '../../assets/images/composing-email.jpg';


export function Home() {
  return (
    <>
      <Helmet>
        <title>MeshOS: Next-generation working environment</title>
      </Helmet>
      <section className="container m-auto px-4 sm:px-6 pb-6 md:pb-40 md:-mt-6 border-box text-center">
        <Type size="title" className="md:mt-20 sm:mb-10 text-center">
          We&apos;re building a next-generation working environment.
        </Type>
        <div className='md:max-w-xl lg:max-w-4xl md:mx-auto'>
          <Type className='text-xl sm:max-w-xl sm:mx-auto mb-6'>
            We all have more information than we can manage, in desktop windows
            and browser tabs. Switching context is not easy.
          </Type>
          <Type className='text-xl sm:max-w-xl sm:mx-auto mb-6'>
            With MeshOS we have a new approach. The information and tools you need
            for each topic live in persistent workspaces, with visual layout, lots
            of capacity, built-in collaboration, AI backing, and other new
            capabilities.
          </Type>
          <Type className='text-xl sm:max-w-xl sm:mx-auto mb-6'>
            Our beta requires a Mac and is free.
          </Type>
          <SignupButton
            className='mt-4'
            size='hero'
            showIcon={false}
            label='Join the Beta'
          />
          {/*
          <video
            preload="auto"
            poster="https://us-west-1-meshos-videos.s3.us-west-1.amazonaws.com/videos/IntroductionToMesh.png"
            className='max-w-[500px] sm:max-w-xl md:max-w-xl lg:max-w-4xl md:mx-auto my-6'
            controls
          >
            <source
              src="https://us-west-1-meshos-videos.s3.us-west-1.amazonaws.com/videos/IntroductionToMesh.mp4"
              type="video/mp4"
            />
          </video>
          */}
        </div>
      </section>
    </>
  );
}

export default Home;
