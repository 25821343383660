/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { TwitterPicker, ColorResult } from 'react-color';
import { Field } from 'formik';

import Type from '../Type';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush, faTimes } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../constants/inputLookups';

export interface InputColorProps {
  label: string;
  content: string;
  onChange?: (hex: string) => void;
  name: string;
  fallback?: string;
  id?: string;
}

export function InputColor(props: InputColorProps) {
  const [open, setOpen] = useState(false);

  const onChangeComplete = (
    color: ColorResult,
    update?: (name: string, hex: string) => any
  ) => {
    if (props.onChange) {
      props.onChange(color.hex);
    }
    if (update && props.name) {
      update(props.name, color.hex);
    }
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const content = (field: Record<string, any>) => (
    <div className="relative mb-3">
      <Type size="subheading">{props.label}</Type>
      <div
        className="p-4 pl-6 pr-6 items-center rounded-t transition"
        style={{ backgroundColor: field.field.value ?? props.fallback }}
        id={props.id}
      >
        <Type inline className="text-white">
          {props.content}
        </Type>
      </div>
      <div className="w-full">
        <Button
          grouped="bottom"
          full
          onClick={toggleOpen}
          color="dark"
          size="small"
          type="button"
        >
          <span>
            {open ? (
              <span>
                <FontAwesomeIcon className="mr-2" icon={faTimes} /> Close Color
                Picker
              </span>
            ) : (
              <span>
                <FontAwesomeIcon className="mr-2" icon={faPaintBrush} /> Change
                Color
              </span>
            )}
          </span>
        </Button>
      </div>
      {open ? (
        <div className="absolute left-0 z-50">
          <TwitterPicker
            {...{
              color: field.field.value || props.fallback,
              colors,
              onChangeComplete: (cr: ColorResult) =>
                onChangeComplete(cr, field.form.setFieldValue),
            }}
          />
        </div>
      ) : null}
    </div>
  );

  return (
    <Field name={props.name}>
      {(field: Record<string, any>) => <div>{content(field)}</div>}
    </Field>
  );
}

export default InputColor;
