/*
 * This is root structure of every page/route.
 */
import React, { PropsWithChildren } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { Outlet } from 'react-router-dom';

import ScrollToTop from '../../components/ScrollToTop';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Root: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div id="body">
      <ScrollToTop />
      <ToastContainer
        className="notification-background"
        progressClassName="notification-progress-bar"
        transition={Slide}
      />
      <Header />
      <div className="lg:m-3 lg:h-3" />
      {children}
      {/* this is where each individual route renders its content */}
      <Outlet />
      <div className="lg:m-3 lg:h-3" />
      <Footer />
    </div>
  );
};

export default Root;
