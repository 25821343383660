import { auth } from './firebase';
import {
  GoogleAuthProvider,
  EmailAuthProvider,
  getAdditionalUserInfo,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  User,
} from "firebase/auth";

import { useAuthState } from 'react-firebase-hooks/auth';

export function useFirebaseAuth() {
  return useAuthState(auth);
}

export async function logIn(email: string, password: string): Promise<User> {
  const response = await signInWithEmailAndPassword(auth, email, password);
  return response.user;
}

export async function logOut() {
  return await signOut(auth);
}

export async function resetPassword(email: string) {
  return await sendPasswordResetEmail(auth, email);
}

export async function changePassword(oldPassword: string, newPassword: string) {
  const user = auth.currentUser;
  if (user?.email) {
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  }
}


/**
 * Logs in using Google. This creates a new account if the user has never logged in before.
 * @returns Firebase User object and boolean indicating whether the account was just created
 */
export async function logInWithGoogle(): Promise<[User, boolean, Record<string, string>?]> {
  if (auth.currentUser) { return [auth.currentUser, false]; }

  // see https://firebase.google.com/docs/auth/web/google-signin
  auth.useDeviceLanguage();
  const provider = new GoogleAuthProvider();

  // see https://developers.google.com/identity/protocols/oauth2/scopes#google-sign-in
  provider.addScope('email');
  provider.addScope('profile');

  // perform the log in using a popup
  const result = await signInWithPopup(auth, provider);

  // we need to determine whether this is a new account
  const info = getAdditionalUserInfo(result);

  // grab the profile which has details we need
  let profile: Record<string, string> | undefined;
  if (info?.profile) {
    profile = info.profile as Record<string, string>;
  }

  return [result.user, info?.isNewUser === true, profile];
}
