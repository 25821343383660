import * as Sentry from "@sentry/react";
import { functions, auth } from './firebase';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';


export enum CreateUserErrorActionCode {
  WeakPassword,
  AccountAlreadyExists,
  LoginRequired,
}

export class CreateUserError extends Error {
  name: string;
  code: CreateUserErrorActionCode;

  constructor(code: CreateUserErrorActionCode, message: string) {
    super(message);
    this.name = "CreateUserError";
    this.code = code;
  }
}

// triggers a new verification email from Firebase, requires a logged in user
export async function sendVerifyEmail(): Promise<void> {
  if (auth.currentUser != null) {
    //console.log(`sending verify email`);

    return await sendEmailVerification(auth.currentUser);
  }

  // "auth/requires-recent-login"
  // "auth/expired-action-code"
  throw new CreateUserError(
    CreateUserErrorActionCode.LoginRequired,
    "You must login before you can verify your email address"
  );
}

// IMPORTANT: this interface must match the type in the cloud function exactly
export interface NewUserRecord {
  firstname: string;
  lastname: string;
  email: string;
  referralCode?: string;
}

// creates a new Firebase user, errors come directly from Firebase SDK
async function createFirebaseUser(user: NewUserRecord, password: string): Promise<string> {
  try {

    // note: when successful, this also logs the user in
    const fbUser = await createUserWithEmailAndPassword(auth, user.email, password);
    const uid = fbUser.user.uid;

    // might as well assign the display name to the Firebase profile
    await updateProfile(
      auth.currentUser!,
      { displayName: user.firstname + ' ' + user.lastname }
    );

    return uid;

  } catch (e) {
    // handle various Firebase errors
    if (e instanceof FirebaseError) {
      switch(e.code) {
      case "auth/weak-password":
        //console.log('weak password');
        throw new CreateUserError(
          CreateUserErrorActionCode.WeakPassword,
          "The password you selected is too weak, try again"
        );


      case "auth/credential-already-in-use":
      case "auth/email-already-in-use":
      case "auth/account-exists-with-different-credential":
        //console.log('account already exists');
        throw new CreateUserError(
          CreateUserErrorActionCode.AccountAlreadyExists,
          "That email address is already in use"
        );
      }
      console.warn("unhandled FirebaseError:", e);
    } else {
      console.warn("unexpected error received:", e);
    }
    throw e; // something unexpected
  }
}

// IMPORTANT: this interface must match the cloud function exactly
export interface SelfSignupNewUserRecord extends NewUserRecord {
  firebaseUid: string;
}

// IMPORTANT: this interface must match the cloud function exactly
export interface CreateUserResponse {
  status: string;
  uid: string;
  meshId: string;
  warnings: string[];
}

// wraps the "userSignUp" callable cloud function
export async function createMeshUser(user: SelfSignupNewUserRecord): Promise<CreateUserResponse> {

  // uncomment for UI testing
  // return new Promise<CreateUserResponse>((resolve, reject) => {
  //   setTimeout(() => {
  //     const resp: CreateUserResponse = {
  //       status: "SUCCESS",
  //       uid: "abc123",
  //       meshId: "83222EF8-5B2F-45EF-AAD2-5E6932AEA34A",
  //       warnings: [],
  //     };
  //     resolve(resp);
  //     //reject(new Error('could not create firebase user: password too short'));
  //   }, 5000);
  // });

  // must be logged in
  if (auth.currentUser == null) {
    throw new CreateUserError(
      CreateUserErrorActionCode.LoginRequired,
      "You must login before you can finish creating your account"
    );
  }

  // invoke the cloud function
  const userSignUp = httpsCallable<SelfSignupNewUserRecord,CreateUserResponse>(functions, 'userSignUp');
  const response = await userSignUp(user);

  return response.data;
}

/**
 * Entire user creation flow
 * @param user User details
 * @param password Password for new user
 * @returns New user details
 */
export async function createUser(user: NewUserRecord, password: string): Promise<CreateUserResponse> {

  // first create the Firebase account
  // note: when successful, this also logs the user in
  const fb_id = await createFirebaseUser(user, password);

  console.log(`firebase user created: ${fb_id}`);

  // create the Mesh UserProfile by calling the cloud function
  const account: SelfSignupNewUserRecord = {
    ...user,
    firebaseUid: fb_id,
  };
  const result = await createMeshUser(account);

  console.log(`Mesh user created: ${result.meshId}`);

  // send a verification email
  try {
    await sendVerifyEmail();
    console.log('verification email sent');
  } catch(e) {
    console.error(`unable to send verification email: `, e);
    if (__PRODUCTION__) {
      Sentry.captureException(e);
    }
  }

  return result;
}
