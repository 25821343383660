import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from '@firebase/auth';
import { getDatabase, connectDatabaseEmulator } from '@firebase/database';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';

// the correct config is injected by webpack at build time
const config = __FIREBASE_CONFIG__;

// initialize Firebase App
const app: FirebaseApp = initializeApp(config);

// initialize Firebase Auth
const auth = getAuth(app);
if (process.env.REACT_APP_AUTH_EMULATOR) {
  console.warn(`using Firebase Auth emulator at ${process.env.REACT_APP_AUTH_EMULATOR}`);
  connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR);
}

// initialize Firebase DB
const db = getDatabase(app);
if (process.env.REACT_APP_DATABASE_EMULATOR_HOST) {
  console.warn(`using Firebase DB emulator at ${process.env.REACT_APP_DATABASE_EMULATOR_HOST}:${process.env.REACT_APP_DATABASE_EMULATOR_PORT}`);
  connectDatabaseEmulator(
    db,
    process.env.REACT_APP_DATABASE_EMULATOR_HOST,
    parseInt(process.env.REACT_APP_DATABASE_EMULATOR_PORT!)
  );
}

// initialize Firebase functions
const functions = getFunctions(app);
if (process.env.REACT_APP_FUNCTION_EMULATOR_HOST) {
  console.warn(`using Firebase Functions emulator at ${process.env.REACT_APP_FUNCTION_EMULATOR_HOST}:${process.env.REACT_APP_FUNCTION_EMULATOR_PORT}`);
  connectFunctionsEmulator(
    functions,
    process.env.REACT_APP_FUNCTION_EMULATOR_HOST,
    parseInt(process.env.REACT_APP_FUNCTION_EMULATOR_PORT!)
  );
}

export { auth, db, functions };
export default app;
