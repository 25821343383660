/*
 * Main application router, using React Router v6
 * see https://reactrouter.com/
 */
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Root from '../../pages/Root';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import AccountMain from '../../pages/AccountMain';
import ClientDownload from '../../pages/ClientDownload';
import SignUp from '../../pages/SignUp';
import TermsAndConditions from '../../pages/TermsAndConditions';
import VerifyEmail from '../../pages/VerifyEmail';
import Error404 from '../../pages/Error404';

import { discordInvite } from '../../constants/urls';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Home /> },
      { path: "login", element: <Login />,
        children: [{ path: ":redirect", element: <Login /> }] },
      { path: "account", element: <AccountMain /> },
      { path: "download", element: <ClientDownload />,
        children: [{ path: ":newUser", element: <ClientDownload /> }] },
      { path: "discord", Component: () => {
        window.location.replace(discordInvite);
        return null;
      }},
      { path: "signup", element: <SignUp />,
        children: [{ path: ":ref", element: <SignUp /> }] },
      { path: "verifyemail", element: <VerifyEmail /> },
      { path: "terms", element: <TermsAndConditions /> },
    ],
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);
export default router;
