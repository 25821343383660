import React, { JSX, useState } from 'react';

import propOrDefault from '../../utils/propOrDefault';
import statusCode from '../../constants/statusCode';
import statusColors from '../../constants/statusColors';
import { sizeLookup, groupedLookup } from '../../constants/inputLookups';

import {
  faEye as EyeIcon,
  faEyeSlash as EyeSlashIcon,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Field } from 'formik';

import Type from '../Type';

export interface InputTextProps {
  label?: string;
  icon?: IconDefinition;
  placeholder?: string;
  note?: string | JSX.Element;
  status?: statusCode;
  size?: 'small' | 'medium' | 'large';
  type?: 'text' | 'email' | 'password' | 'number';
  grouped?: 'none' | 'left' | 'center' | 'right';
  name?: string;
  button?: JSX.Element;
  inline?: boolean;
  disabled?: boolean;
  field?: boolean;
  default?: string;
  onChange?: (val: string) => void;
  setFieldValue?: (name: string, val: unknown) => void;
  prefix?: string;
  noComplete?: boolean;
}

export const InputText: React.FC<InputTextProps> = (props) => {
  const size = propOrDefault(props.size, 'medium');
  const status = statusCode[propOrDefault(props.status, statusCode.none)];
  let type = propOrDefault(props.type, 'text');
  const grouped = propOrDefault(props.grouped, 'none');
  const inline = propOrDefault(props.inline, false);
  const disabled = propOrDefault(props.disabled, false);
  const defaultVal = propOrDefault(props.default, '');

  // special handling for password fields
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordType = type === 'password';
  if (passwordType && passwordVisible) {
    type = 'text';
  }

  const fieldClass =
    'border-none appearance-none leading-tight focus:outline-hidden ' +
    sizeLookup[size] +
    ' ' +
    (inline ? '' : 'w-full') +
    ' ' +
    (disabled ? 'bg-gray-300 cursor-not-allowed' : '');

  return (
    <div
      className={
        'mb-4 font-sans' +
        (inline ? ' inline-block' : '') +
        (grouped !== 'none' ? ' mr-0' : inline ? ' mr-4' : '')
      }
    >
      {props.label ? (
        <label>
          <Type size="subheading">{props.label}</Type>
        </label>
      ) : null}
      <span
        className={
          'transition overflow-hidden flex items-center border-box border border-solid shadow-sm focus-within:border-blue-300' +
          ' border-' +
          statusColors[status] +
          ' ' +
          groupedLookup[grouped] +
          ' ' +
          (disabled ? 'bg-gray-300' : 'bg-white')
        }
      >
        {props.icon ? (
          <span className="pl-2 text-sm text-gray-600">
            <FontAwesomeIcon icon={props.icon} />
          </span>
        ) : null}
        {props.prefix ? (
          <span className="pl-2 text-sm text-gray-600 -mr-3 z-10 font-medium">
            {props.prefix}
          </span>
        ) : null}
        {props.field && props.name ? (
          <Field
            className={fieldClass}
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            type={type}
            autoCorrect={props.noComplete ? 'off' : 'on'}
            autoCapitalize={props.noComplete ? 'off' : 'on'}
            autoComplete={props.noComplete ? 'off' : 'on'}
            {...(props.onChange
              ? {
                onChange: (e: Event) => {
                  if (!e.target) { return; }
                  const target = e.target as HTMLInputElement;
                  if (props.setFieldValue && props.name) {
                    props.setFieldValue(props.name, target.value);
                  }
                  if (props.onChange) {
                    props.onChange(target.value);
                  }
                },
              }
              : undefined)}
          />
        ) : (
          <input
            id={props.name ?? undefined}
            className={fieldClass}
            type={type}
            placeholder={props.placeholder}
            disabled={disabled}
            defaultValue={defaultVal}
            onChange={props.onChange ? (e) => {if (props.onChange) props.onChange(e.target.value);} : undefined}
            autoCorrect={props.noComplete ? 'off' : 'on'}
            autoCapitalize={props.noComplete ? 'off' : 'on'}
            autoComplete={props.noComplete ? 'off' : 'on'}
          />
        )}
        {props.button ?? null}
        {passwordType ? (
          <span className="pr-2 text-sm text-gray-600">
            <FontAwesomeIcon
              icon={passwordVisible ? EyeSlashIcon : EyeIcon}
              onClick={() => setPasswordVisible(!passwordVisible)}
            />
          </span>
        ) : null}
      </span>
      {props.note ? (
        <Type
          size="subtext"
          className={
            (status === 'none' ? 'text-gray-600' : 'text-' + statusColors[status])
          }
        >
          {props.note}
        </Type>
      ) : null}
    </div>
  );
};

export default InputText;
