// react stuff
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope as EnvelopeIcon } from '@fortawesome/free-solid-svg-icons';

// utilities
import { useFirebaseAuth } from '../../api/auth';
import statusCode from '../../constants/statusCode';
import {
  sendVerifyEmail,
} from '../../api/createUser';

// our components
import ActivityIndicator from '../../components/ActivityIndicator';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Type from '../../components/Type';

export const VerifyEmail: React.FC = () => {
  const [user, authLoading, authError] = useFirebaseAuth();
  const [resendClicked, setResendClicked] = useState(false);

  const title = (
    <Helmet>
      <title>MeshOS: Verify Email</title>
    </Helmet>
  );

  // log errors in Sentry
  if (__PRODUCTION__) {
    useEffect(() => {
      if (authError) {
        Sentry.captureException(authError);
      }
    }, [authError]);
  }

  // errors loading auth
  if (authError) {
    console.error(authError);
    return (
      <>
        {title}
        <div className="container sm:py-32 m-auto border-box max-w-3xl">
          <Alert
            title="Something went wrong!"
            status={statusCode.danger}
            message="Unable to determine user status. Please reload and try again."
            closeable={false}
          />
        </div>
      </>
    );
  }

  // auth status is loading (should only take miliseconds)
  if (authLoading) {
    return (
      <>
        {title}
        <ActivityIndicator className='flex justify-center' size="large" />
      </>
    );
  }

  // redirect to login page if not logged in
  if (!user) {
    return <Navigate to="/login/verifyemail" replace />;
  }

  // email already verified?
  if (user.emailVerified) {
    // redirect to download page
    return <Navigate to="/download/newUser" />;
  }

  // normal state, show the message
  return (
    <>
      {title}
      <div className='w-full flex flex-col items-center gap-y-3 mb-6'>
        <FontAwesomeIcon
          className='size-45 sm:size-60 text-gray-500'
          icon={EnvelopeIcon}
        />
        <Type size="title">Verify your email address</Type>
        <Type>
          We&apos;ve sent an email to <code className='bg-gray-200'>{user.email}</code> to verify your email address.<br />
          Click the link in that email to activate your account.
        </Type>
        {resendClicked ?
          <Type className="p-6" size="subheading">Email sent</Type>
          :
          <Button
            onClick={() => {
              setResendClicked(true);
              sendVerifyEmail()
                .then(() => {
                  console.log('verification email sent');
                })
                .catch((e) => {
                  console.error('failed to send verification email:', e);
                });
            }}
          >
            Resend
          </Button>
        }
      </div>
    </>
  );
};

export default VerifyEmail;
